import React from 'react'

const Image = ({ url, lightgallery }) => {
    if (lightgallery) {
        return (
            <a href={process.env.REACT_APP_API_URL + '/resource/suchomel/' + url}>
                <img src={process.env.REACT_APP_API_URL + '/resource/suchomel/' + url} alt={url} />
            </a>
        )
    }
    return (
        <img src={process.env.REACT_APP_API_URL + '/resource/suchomel/' + url} alt={url} />
    )
}

export default Image