import { useEffect, useState } from 'react';
import { About, Footer, Gallery, Menu, MenuButton } from '.';
import { IconChevronDown, IconMail, IconPhone } from '@tabler/icons-react';
import axios from 'axios';

const Main = () => {
    const [menuState, setMenuState] = useState(false);
    const [text, setText] = useState()

    const getText = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts',
                    type: 'name',
                    specific: 'footer'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setText(JSON.parse(response.data[0].text))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getText()
    }, [])

    return (
        <>
            <Menu menuState={menuState} setMenuState={setMenuState} />
            <MenuButton setMenuState={setMenuState} menuState={menuState} />
            <header id='home'>
                <div className="content">
                    <div className="title">
                        <h1>Michal Suchomel</h1>
                        <p className="subtitle">truhlářství</p>
                        <p className="header-contact">
                            <a href={'tel:' + text?.phone.split(' ').join('')} className="phone"><IconPhone stroke={1.5} size={16} />{text?.phone}</a>
                            <a href={'mail:' + text?.mail} className="mail"><IconMail stroke={1.5} size={16} />{text?.mail}</a>
                        </p>
                    </div>
                    <a href="#about" className="continue">
                        <p>Pokračovat</p>
                        <IconChevronDown size={48} stroke={1.5} />
                    </a>
                </div>
            </header>
            <main id="main">
                <About />
                <Gallery />
            </main>
            <Footer text={text} />
        </>
    )
}

export default Main