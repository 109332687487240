import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from '.'
import { Link } from 'react-router-dom'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'


const Gallery = () => {
    const [images, setImages] = useState([])

    const getImages = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallery'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setImages(response.data.slice(0,6))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <section className="section gallery" id='gallery'>
            <div className="background-text"><p>02</p></div>
            <h2 className='section__header'>Galerie</h2>
            <div className="images">
                {/* {images?.map(image => {
                    return <Image url={image.imgUrl} key={image.id} />
                })} */}
                <LightGallery plugins={[lgThumbnail, lgZoom]} className='lightgallery' >
                    {images?.map(image => {
                        return <Image url={image.imgUrl} lightgallery key={image.id} />
                    })}
                </LightGallery>
            </div>
            <Link to='/fotogalerie' className='btn btn-secondary'>Zobrazit další</Link>
        </section>
    )
}

export default Gallery