import React from 'react'

const MenuButton = ({ setMenuState, menuState }) => {
    return (
        <div className={menuState ? 'menu-opener cross' : 'menu-opener'} onClick={() => {setMenuState(!menuState)}} >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </div>
    )
}

export default MenuButton