import React from 'react'
import Logo from '../../img/logo/logo.png'

const Menu = ({ menuState, setMenuState }) => {
    return (
        <div className={menuState ? 'menu open' : 'menu'}>
            <nav className="navbar">
                <a href="/#home" className="navbar-brand" onClick={() => {setMenuState(!menuState)}}>
                    <img src={Logo} alt="Logo" className="navbar-brand__img" />
                    <p className="navbar-brand__title">Michal Suchomel</p>
                </a>
                <ul className="navbar-menu">
                    <li className="navbar-menu__item">
                        <a href="/#about" className="navbar-menu__link" onClick={() => {setMenuState(!menuState)}}>01. O nás</a>
                    </li>
                    <li className="navbar-menu__item">
                        <a href="/#gallery" className="navbar-menu__link" onClick={() => {setMenuState(!menuState)}}>02. Fotogalerie</a>
                    </li>
                    <li className="navbar-menu__item">
                        <a href="/#contact" className="navbar-menu__link btn-primary" onClick={() => {setMenuState(!menuState)}}>03. Kontakt</a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Menu