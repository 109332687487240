import React, { useState, useEffect } from 'react'
import axios from 'axios'

const About = () => {
    const [text, setText] = useState()

    const getText = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts',
                    type: 'name',
                    specific: 'onas'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setText(response.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getText()
    }, [])

    return (
        <section className='section about' id='about'>
            <div className="background-text"><p>01</p></div>
            <h2 className='section__header'>{text?.header}</h2>
            <p className='section__text'>{text?.text}</p>
        </section>
    )
}

export default About