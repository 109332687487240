import { Routes, Route } from 'react-router-dom';
import './style/style.scss';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { GallerySite, Main } from './components';

const App = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/fotogalerie' element={<GallerySite />} />
            </Routes>
        </>
    )
}

export default App