import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image, Menu, MenuButton, Footer } from '.'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

const GallerySite = () => {
    const [images, setImages] = useState([])
    const [categories, setCategories] = useState()
    const [menuState, setMenuState] = useState(false)
    const [category, setCategory] = useState(0)

    const getImages = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallery'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            const responseCategories = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'categories',
                    type: 'categorySet',
                    specific: 1
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setImages(response.data)
            setCategories(responseCategories.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        window.scrollTo(0,0)
        getImages()
    }, [])

    return (
        <>
            <Menu menuState={menuState} setMenuState={setMenuState} />
            <MenuButton setMenuState={setMenuState} menuState={menuState} />
            <main className='subsite'>
                <h1>Galerie</h1>
                <section className='section gallery'>
                    <div className="filter">
                        <select defaultValue="0" onChange={(e) => {setCategory(parseInt(e.target.value))}}>
                            <option value="0" hidden>Vyberte kategorii</option>
                            <option value="0">Vše</option>
                            {categories?.map(cat => {
                                return <option value={cat.id} key={cat.id}>{cat.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="images">
                        <LightGallery plugins={[lgThumbnail, lgZoom]} className='lightgallery' >
                            {images?.filter(image => image.category === category || category === 0).map(image => {
                                return <Image url={image.imgUrl} lightgallery key={image.id} />
                            })}
                        </LightGallery>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default GallerySite